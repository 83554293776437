@import '../../styles/variables.scss';

.container {
  display: flex;
  min-width: $pageMaxWidth;

  .content {
    display: flex;
    flex-flow: column;
    width: 55%;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.4);

    .padding {
      padding-left: 20%;
      padding-right: 12%;
    }

    .top {
      display: flex;
      align-items: flex-end;
      padding-bottom: 50px;
      background-color: $otcWhite;
      height: 275px;

      .welcome {
        display: block;
        font-size: 24px;
        color: $otcGreen;
        font-weight: $fontWeightSemiBold;
        margin-bottom: $mediumSpace;
      }

      img {
        width: 335px;
      }
    }
    
    .bottom {
      flex: 1 1 auto;
      background-color: $otcGreen;
      padding-top: 6%;

      a {
        color: $otcWhite;
        text-decoration: underline;
      }

      .description {
        font-size: 32px;
        color: $otcWhite;
        font-weight: $fontWeightExtraBold;
        line-height: 48px;
      }

      .cta {
        margin-top: $XLargeSpace;
        font-size: 24px;
        font-weight: $fontWeightSemiBold;
        color: $otcWhite;
      }

      .footerGroup {
        margin-top: $XXLargeSpace;
        list-style-type: none;
        padding: 0;
        font-size: 18px;

        .item {
          margin-bottom: $mediumSpace;
        }
      }
    }
  }
  
  .signIn {
    display: flex;
    align-items: center;
    padding-left: 6%;
    height: 100vh;
    width: 45%;

    .wrapper {
      width: 340px
    }

    .forgot {
      color: $otcGreen;
      font-size: 13px;
    
      &:hover {
        text-decoration: underline;
      }
    }
    
    .separator {
      position: relative;
      border-top: 1px solid $otcLightGray;
      margin: $largeSpace 0 (-$smallSpace);
      text-align: center;
    
      .text {
        position: relative;
        background-color: #fff;
        padding: 0 .5em;
        color: #6c7378;
        top: -.7em;
      }
    }
    
    .footerSignup {
      margin-top: $largeSpace;
      text-align: center;
      
      .link {
        cursor: pointer;
        color: $otcGreen;
    
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}