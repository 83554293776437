@import '../../styles/variables.scss';

.container {
  display: flex;
  width: 100%;

  .select {
    display: flex;
    background: #f5f5f5;
    height: 100%;
    padding-left: 8px;
    align-items: center;
  }

  .dx-dropdowneditor-button.dx-button-mode-contained, .dx-dropdowneditor-button.dx-button-mode-outlined, .dx-dropdowneditor-button.dx-button-mode-text {
    background-color: #f5f5f5;
    width: 15px;
  }

  .dx-dropdowneditor-icon {
    width: auto;
  }
}

.staticCode {
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 0 8px 5px;
  color: #333;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;

  &.isFocus {
    border-color: $otcGreen;
  }

  &.disabled {
    opacity: .5;
  }
}

.input {
  width: 100%;
}

.countryField {
  display: inline-block;
}

.hide {
  display: none;
}

abbr {
  font-size: 12px;
    line-height: 16px;
    margin: 0;
    color: #292929;
    font-weight: $fontWeightLight;
}