@import '../../styles/variables.scss';

.container {
  min-width: 335px;
  max-width: 375px;
  box-sizing: content-box;
  text-align: left;
}

.row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: $smallSpace;
}

.header {
  font-weight: $fontWeightBlack;
  font-size: 14px;
}

.title {
  font-weight: $fontWeightExtraBold;
  font-size: 14px;
}

.value {
  font-weight: $fontWeightLight;
  font-size: 14px;
}