@import '../../../styles/variables.scss';

.complete {
  border: 1px solid $otcGray;
  border-left: none;
  border-right: none;
  padding: $XLargeSpace 0;
}

.incomplete {
  @include h3;

  .click {
    color: $otcTextLinkColor;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.success {
  display: flex;
  color: $otcGreen;
  font-size: 20px;

  .icon {
    margin-right: $mediumSpace;
  }
}