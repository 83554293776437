@import '../../styles/variables.scss';

.container {
  .list {
    > div {
      display: flex;
      margin-bottom: $smallSpace;
    }
  }

  .incomplete {
    @include incompleteTitle;
    
    .title {
      display: inline-block;
    }
  }
}