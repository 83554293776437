@import '../../styles/variables.scss';

.flex {
  display: flex;
}

.children {
  display: block;
  padding-left: $XLargeSpace;

  &.disabled {
    opacity: .5;
  }
}

.customChildren {
  display: block;
  padding-left: $smallSpace;
}

.error {
  display: block;
  margin: 0 4px 8px;
}