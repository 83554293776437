@import '../../styles/variables.scss';

.container {
  margin-bottom: $largeSpace;
  border: 1px solid $otcGray;

  .titleBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $mediumSpace;
  }

  .typeContainer {
    display: flex;
    align-items: center;

    .typeIcon {
      display: flex;
      align-items: center;
      height: 40px;
      width: 112px;

      &.green {
        background-color: $otcGreen;
      }

      &.orange {
        background-color: $otcOrange;
      }

      &.pink {
        background-color: $otcPink;
      }

      &.otcidGray {
        background-color: $otcidGray;
      }

      .logo {
        width: 100%;
        height: 16px;
      }
    }

    .type {
      font-size: 14px;
      margin-left: $mediumSpace;
    }
  }

  .status {
    font-size: 14px;
    font-weight: $fontWeightExtraBold;
  }

  .info {
    padding: $smallSpace $mediumSpace $mediumSpace;

    .name {
      font-size: 20px;
      font-weight: $fontWeightExtraBold;
      margin-bottom: $mediumSpace;
      word-break: break-all;
    }

    .cancel {
      float: left;
      @include link;
      font-size: 14px;
    }

    .seeMoreLink {
      cursor: pointer;
      float: right;
      font-size: 14px;

      .chevron {
        transition: transform 250ms;
        margin-left: $xsmallSpace;
        -webkit-font-smoothing: antialiased;

        &.open {
          transform: rotate(-180deg);
        }
      }
    }

    .date {
      display: inline-block;
      font-size: 14px;
      margin-right: $mediumSpace;

      &.inProgress {
        float: right;
      }
    }

    .cta {
      text-align: right;
    }
  }

  .flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .metaInfo {
    width: 100%;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .bottomRow {
    margin-top: $mediumSpace;
  }

  .complete {
    font-size: 14px;
    color: $otcGreen;
    font-weight: $fontWeightLight;
    flex: 0 0 100%;
    align-self: flex-start;
  }

  .subText {
    color: $otcBlack;
  }

  .dialogContainer {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;

    .dialog {
      width: 75%;
      height: 100%;
    }
  }
}

.divider {
  display: block;
  height: 1px;
  background-color: $otcGray;
  opacity: 0;
  transition: all 250ms;

  &.open {
    margin-top: $mediumSpace;
    border-width: 1px;
    opacity: 1;
    transition: all 250ms;
  }
}

.seeMoreContent {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 250ms;

  &.open {
    margin-top: $smallSpace;
    max-height: 100px;
    opacity: 1;
    transition: all 250ms;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-gap: $mediumSpace;
  align-items: end;
}