@import '../../styles/variables.scss';

.container {
  .filter {
    margin-bottom: $mediumSpace;
  }

  .selectedFilters {
    display: flex;
    flex-wrap: wrap;
    margin-top: $mediumSpace;
  }
}