@import '../../styles/variables.scss';

.bold {
  font-weight: $fontWeightBold;

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}