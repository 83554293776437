@import '../../styles/variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid $otcMediumGray;
  background-color: $otcWhite;
  font-weight: $fontWeightSemiBold;

  &.blank {
    background-color: rgba(0,0,0,.1);
    border-color: $otcGray;
  }

  &.icon {
    font-size: 24px;
    border-color: $otcGreen;
    color: $otcGreen;
  }
}