@import './variables.scss';
@import './flags16.scss';

.mtNone {
  margin-top: 0;
}

.mtSm {
  margin-top: $smallSpace;
}

.mtMed {
  margin-top: $mediumSpace;
}

.mtLg {
  margin-top: $largeSpace;
}

.mtXL {
  margin-top: $XLargeSpace;
}

.mtXXL {
  margin-top: $XXLargeSpace;
}

.mbNone {
  margin-bottom: 0;
}

.mbSm {
  margin-bottom: $smallSpace;
}

.mbMed {
  margin-bottom: $mediumSpace;
}

.mbLg {
  margin-bottom: $largeSpace;
}

.mbXL {
  margin-bottom: $XLargeSpace;
}

.mbXXL {
  margin-bottom: $XXLargeSpace;
}

.innerGrid {
  display: grid;
  grid-gap: $mediumSpace;
  grid-template-columns: repeat(6, 1fr);

  .col2 {
    grid-column: span 2;
  }

  .col3 {
    grid-column: span 3;
  }

  .col4 {
    grid-column: span 4;
  }

  .col5 {
    grid-column: span 5;
  }

  .col6 {
    grid-column: span 6;
  }

  .newRow3 {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .newRow4 {
    grid-column-start: 1;
    grid-column-end: 5;
  }
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  &.no-scroll {
    overflow: hidden;
  }

  max-width: 100vw;
  width: 100%;
  font-family: $otcFontPrimary, sans-serif;
  // ensures advertising iframes don't add space below footer
  > iframe {
    position: absolute;
    height: 0;
    width: 0;
  }
}

html {
  // base font size for rem
  font-size: 16px;
  // adjust base font for very small screens
  @media screen and (max-width: 340px) {
    font-size: 13px;
  }
}

// Hide proprietary IE junk
input::-ms-clear {
  display: none;
}
input::-ms-reveal {
  display: none;
}

.middle {
    top: 50%;
}