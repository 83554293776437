@import '../../../../styles/variables.scss';

.description {
  margin-bottom: $mediumSpace;
}

.list {
  display: flex;
  padding-left: $smallSpace;

  .column {
    margin-right: $largeSpace;

    .item {
      display: flex;
      align-items: center;
  
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #00a88f;
        margin-right: 6px;
      }
    }
  }
}