@import '../../styles/variables.scss';
$width: 215px;

.container {
  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px $mediumSpace;
    background-color: $otcWhite;
    width: $width;
    border: 1px solid $otcGray;
    font-size: 16px;
    cursor: pointer;

    .icon {
      font-size: 1.5em;
    }
  }

  .dropdown {
    position: absolute;
    width: $width;
    margin-top: 4px;
    z-index: 100;
    border: 1px solid $otcGray;
    padding: $mediumSpace;
    background-color: $otcWhite;

    .checkboxItem {
      margin-bottom: $smallSpace;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}