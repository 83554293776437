// app portal
$pageMaxWidth: 1040px;
$headerHeight: 75px;
// style-guide colors
$otcBackground: #fff;
$otcWhite: #fff;

$otcGreen: #00a88f;
$otcGreenRGB: (0, 168, 143);
$otcGreenSecondary: #32bbab;
$otcOrange: #f7931d;
$otcOrangeSecondary: #fab061;
$otcPink: #ec098c;
$otcPinkSecondary: #f072ab;

$otcBlack: #292929;
$otcDarkGray: #444444;
$otcMediumGray: #6D7278;
$otcGray: #979797;
$otcLightGray: #e2e2e2;
$otcDarkGrey: $otcDarkGray;
$otcGrey: $otcGray;
$otcLightGrey: $otcLightGray;

$otcTextLinkColor: #48ACF0;
$otcSuccessColor: $otcGreen;
$otcWarningColor: $otcOrange;
$otcErrorColor: #E02020;
$otcWarningColor: #cd5a68;

$otcidGray: #77787B;

// max height for animations
$maxHeightFull: 1500px;

// style-guide fonts
$otcFontPrimary: 'Nunito Sans', 'Helvetica', 'Arial', sans-serif;
$otcFontSecondary: 'Source Serif Pro', 'Georgia', serif;

$fontWeightLight: 200;
$fontWeightRegular: 400;
$fontWeightSemiBold: 600;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
$fontWeightBlack: 900;

$lineHeight: 24px;

// spacing
$xsmallSpace: 4px;
$smallSpace: 8px;
$mediumSpace: 16px;
$largeSpace: 24px;
$XLargeSpace: 32px;
$XXLargeSpace: 64px;

@mixin btnHover {
  box-shadow: 0 0 4px 4px #E7E7E7;
}

@mixin bgGreen($alpha: .4) {
  background-color: rgba(0, 168, 143,$alpha);
}

@mixin borderGreen($alpha: .4) {
  border-color: rgba(0, 168, 143, $alpha);
}

// global hovers

@mixin green-link {
  &:hover {
    color: $otcGreen !important;
  }
}

@mixin orange-link {
  &:hover {
    color: $otcOrange  !important;
  }
}

@mixin pink-link {
  &:hover {
    color: $otcPink !important;
  }
}

@mixin gray-link {
  &:hover {
    color: $otcGray !important;
  }
}

@mixin black-link {
  &:hover {
    color: #000 !important;
  }
}

// style-guide typography helpers
@mixin h1 {
  font-size: 24px;
  font-weight: $fontWeightSemiBold;
}

@mixin h2 {
  font-size: 20px;
  font-weight: $fontWeightBold;
}

@mixin h3 {
  font-size: 16px;
  font-weight: $fontWeightBold;
}

@mixin caption {
  font-size: .75em;
  color: $otcGray;
  display: inline-block;
}

@mixin link {
  cursor: pointer;
  color: $otcTextLinkColor;
  text-decoration: none;
}

@mixin button {
  border: 2px solid $otcGreen;
  font-weight: bold;
  color: $otcGreen;
  background: none;
  background-color: transparent;
  text-transform: uppercase;
  padding: .6em 1.2em;
  cursor: pointer;
  font-size: .9375rem;
  outline: none;
  transition: background-color 0.2s ease-in-out;
  display: inline-block;
  text-decoration: none !important;
  border-radius: 0 !important;
  letter-spacing: .035em;

  &:hover {
    background-color: $otcGreen;
    color: $otcWhite;
  }

  &:disabled {
    background-color: transparent;
    border-color: $otcLightGray;
    color: $otcLightGray;
    cursor: default;

    &:hover {
      background-color: transparent;
      border-color: $otcLightGray;
      color: $otcLightGray;
    }
  }
}

@mixin sideColumn {
  @include lg {
    width: 300px;
  }
}

// other style-guide helpers
$dividerGray: solid 1px $otcLightGray;
$dividerGrey: $dividerGray;
$dividerGreen: solid 1px $otcGreen;
$dividerThick: solid 5px $otcGreen;
$dividerMedium: solid 3px $otcGreen;

// style-guide breakpoints and media query helpers
$bp-tablet: 768px;
$bp-desktop: 1024px;
$max-width: 1280px;

@mixin sm {
  @media screen and (max-width: #{$bp-tablet - 1px}) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: #{$bp-tablet}) and (max-width: #{$bp-desktop - 1px}) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: #{$bp-desktop}) {
    @content;
  }
}

@mixin sm-md {
  @media screen and (max-width: #{$bp-desktop - 1px}) {
    @content;
  }
}

@mixin md-lg {
  @media screen and (min-width: #{$bp-tablet}) {
    @content;
  }
}

// default link
a {
  @include link;
}


// smooth out placeholder inconsistencies
// (autoprefixer isn't adding the ms prefix which is needed for IE)
@mixin placeholder {
  &::placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
}

@mixin incompleteTitle {
  border-bottom: 3px solid $otcErrorColor;
  margin-bottom: $mediumSpace;
}

@mixin notValid {
  border-bottom-color: $otcErrorColor;
  border-bottom-width: 3px;
}