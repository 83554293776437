@import '../../styles/variables.scss';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $mediumSpace;
    min-height: 64px;
    border: solid 1px $otcBlack;
    width: 100%;
    color: $otcBlack;

    .verticalAlign {
        display: flex;
        flex: 1;
        min-width: 0;
    }
    .centerAlign {
        display: flex;
        align-items: center;
    }

    &.medium {
        min-height: 48px;

        .title {
            font-size: 20px;
        }
    }

    &.small {
        min-height: 40px;

        .title {
            line-height: 18px;
        }
    }

    .title {
        font-size: 16px;
        font-weight: $fontWeightBold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


}