@import '../../styles//variables.scss';

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  font-size: 14px;
  font-weight: 400;

  .content {
    grid-column-start: 3;
    grid-column-end: 11;
  
    .appTitle {
      text-align: center;
    }
  
  p {
      font-weight: 400;
    }

 li {
      line-height: $lineHeight;
      letter-spacing: -.1px;
    }
  }
}

p > b {
  font-weight: $fontWeightBold;
}