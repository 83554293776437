@import '../../styles/variables.scss';

.container {
  display: block;
  width: 690px;
  margin: 0 auto;
 
  .title {
    font-size: 32px;
    font-weight: $fontWeightSemiBold;
    margin-bottom: $XLargeSpace;
  }
}