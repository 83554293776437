@import '../../styles/variables.scss';

.footerSignup {
  margin-top: $largeSpace;
  text-align: center;
  
  a {
    color: $otcGreen;

    &:hover {
      text-decoration: underline;
    }
  }
}