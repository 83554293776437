@import '../../styles/variables.scss';

.container {
  counter-reset: number-counter;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin: 0 0 0.5rem 0;
    counter-increment: number-counter;
    position: relative;
    margin-bottom: $mediumSpace;
    font-weight: $fontWeightBold;
    min-height: 25px;

    &::before {
      content: counter(number-counter);
      font-size: 14px;
      font-weight: $fontWeightBold;
      position: absolute;
      --size: 25px;
      left: calc(-1 * var(--size) - 10px);
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      top: 0;
      border: 1px solid $otcBlack;
      border-radius: 50%;
      text-align: center;
    }
  }
}