@import '../../../styles//variables.scss';

.container {
  display: flex;

  .connector {
    width: 2px;
    background-color: $otcGray;
    flex-grow: 1;
  }

  .flow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: $mediumSpace;
    margin-left: -48px;
  }

  .result {
    margin: 4px 0 $XLargeSpace;
  }

  .component {
    width: 100%;
  }
}