@import '../../styles/variables.scss';

.container {
  .list {
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;

    .item {
      display: flex;
      align-items: baseline;
      font-size: 12px;
      gap: $smallSpace;

      &.valid {
        color: $otcGreen;
      }

      &.error {
        color: $otcErrorColor;
      }
    }
  }
}