@import '../../styles/variables.scss';

.container {
  width: 100%;
  margin-bottom: $mediumSpace;

  .header {
    display: flex;

    svg {
      font-size: 24px;
      margin-right: 10px;
    }

    .title {
      margin-bottom: 0;
    }
  }

  .button {
    width: 50%;
  }
}