@import '../../styles/variables.scss';

.container {
  display: grid;
  grid-column-gap: $mediumSpace;
  grid-template-columns: repeat(12, 1fr);

  nav {
    grid-column: span 3;
  }
  
  main {
    grid-column: span 8;
    grid-column-end: 13;
  }
}