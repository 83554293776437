@import '../../../styles/variables.scss';

.links {
  padding-left: $XLargeSpace;
}

.title {
  display: inline-block;
}

.billingMenu{
  padding-left: $XLargeSpace;
}

.customCheckbox {
  font-weight: $fontWeightBold;

  p {
    font-weight: $fontWeightBold;
  }
}