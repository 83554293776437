@import '../../../styles/variables.scss';

.container {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.add {
  font-weight: $fontWeightExtraBold;

  .addText {
    margin-left: $smallSpace;
  }
}