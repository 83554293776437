@import '../../styles/variables.scss';

.container {
    position: fixed;
    display: flex;
    justify-content: center;
    padding-top: 250px;
    z-index: 99999999;
    background-color: rgba(0, 0, 0, .5);
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: $pageMaxWidth;
        height: 275px;
        background-color: $otcWhite;
        border: 1px solid $otcGray;
    }

    .title {
        color: $otcGreen;
    }

    .errorTitle{
      color: $otcErrorColor;
    }

    .desc {
      width: 80%;
      text-align: center;
    }
}

// PROCESS ANIMATION

.loader {
    margin: $largeSpace 0;
    font-size: 10px;
    text-indent: -9999em;
    width: 6em;
    height: 6em;
    border-radius: 50%;
    background: #000000;
    background: -moz-linear-gradient(left, $otcGreen 10%, rgba(0,0,0, 0) 42%);
    background: -webkit-linear-gradient(left, $otcGreen 10%, rgba(0,0,0, 0) 42%);
    background: -o-linear-gradient(left, $otcGreen 10%, rgba(0,0,0, 0) 42%);
    background: -ms-linear-gradient(left, $otcGreen 10%, rgba(0,0,0, 0) 42%);
    background: linear-gradient(to right, $otcGreen 10%, rgba(0,0,0, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: $otcGreen;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }