@import '../../styles/variables.scss';

.container {
  a {
    display: block;
    text-decoration: none;
    margin-bottom: $smallSpace;

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    margin: $mediumSpace 0;
    border-top: $otcGray solid 1px;
    border-bottom: none;
  }
}