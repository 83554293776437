@import '../../styles/variables.scss';

.loggedIn {
  display: block;
  max-width: $pageMaxWidth;
  margin: $headerHeight auto 0;
  padding: $XLargeSpace 0;
}

.overlay {
  width: 100vw;
  display: block;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  
  .dialog {
    position: fixed;
    width: 475px;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.error {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 125px;
}