@import '../../styles/variables.scss';

.container {
  max-width: 425px;
  margin: 0 auto;

  &.formOnly {
    width: 100%;
    margin: 0;
  };

  .wrapper {
    padding: $largeSpace;
    border: 1px solid $otcLightGray;

    &.hideBorder {
      padding: 0;
      border: none;
    }
  }

  .logo {
    display: block;
    width: 250px;
    margin: $XLargeSpace auto;
  }

  .footerGroup {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    text-align: center;
    padding: 0;

    .item {
      display: inline-block;

      .link {
        font-size: 14px;
        color: $otcGreen;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}