@import '../../styles/variables.scss';

.container {
  h2 {
    @include h2;
  }

  h3 {
    @include h3;
  }
}

.affiliationSelect {
  width: 50%;
}

.error {
  margin-top: 10px;
  color: $otcErrorColor;
}

.controls {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: $mediumSpace;
  margin-top: 75px;

  .backCta {
    grid-column: span 3;
  }
}

.listHeader {
  .label {
    font-weight: $fontWeightExtraBold;
    font-size: 12px;
    color: $otcGray;
    padding: $smallSpace;
  }
}