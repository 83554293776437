@import '../../styles/variables.scss';

.backBtn {
  position: absolute;
  margin-top: -$XLargeSpace;
}

.result {
  display: flex;

  .message {
    margin-left: $mediumSpace;
  }

  .title {
    @include h1;
  }
  
  .success {
    color: $otcGreen;
  }

  .resultSquare {
    margin-left: -48px;
  }
}