@import '../../styles/variables.scss';

.container {
  display: grid;
  grid-column-gap: $mediumSpace;
  grid-template-columns: repeat(12, 1fr);

  nav {
    grid-column: span 3;
  }
  
  main {
    grid-column: span 6;
    grid-column-end: 11;
    padding-bottom: $XXLargeSpace;
  }

  .rightRail {
    justify-self: flex-end;
    grid-column-end: 13;
  }
}

.submittedContainer {
  width: 700px;
  margin: 0 auto;

  .appTitle {
    text-align: center;
    margin-bottom: $XLargeSpace;
  }

  .title {
    color: $otcGreen;
  }

  .confirmation {
    display: flex;
    font-size: 16px;
    margin: $XLargeSpace 0;
    color: $otcGreen;

    .icon {
      margin-right: $mediumSpace;
    }
  }
}