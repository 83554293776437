@import '../../styles/variables.scss';

.container {
  section {
    margin-bottom: $XLargeSpace;
  }

  .filterContainer {
    margin-bottom: $largeSpace;
    .filters {
      display: flex;
  
      > div:first-child {
        margin-right: $smallSpace;
      }
    }
  }
}

.tooltipContainer {
  min-width: 300px;
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  margin-top: $smallSpace;
}

.tooltipHeader {
  font-weight: $fontWeightBlack;
  font-size: 14px;
}

.tooltipTitle {
  font-weight: $fontWeightExtraBold;
  font-size: 14px;
}

.tooltipValue {
  font-weight: $fontWeightLight;
  font-size: 14px;
}