@import '../../styles/variables.scss';

.links {
  padding-left: 18px;

  li {
    margin: $smallSpace 0;
  }
}

.phone {
  color: $otcTextLinkColor;
}