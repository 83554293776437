@import '../../styles/variables.scss';

.label {
  display: flex;
  justify-content: center;
  background-color: $otcBlack;
  color: $otcWhite;
  font-size: 14px;
  padding: 3px $smallSpace 1px;
  margin-bottom: $smallSpace;
  
  &:not(:last-child) {
    margin-right: $smallSpace;
  }

  .status {
    font-weight: $fontWeightLight;
  }

  .icon {
    cursor: pointer;
    margin-left: $mediumSpace;
    padding-top: 1px;
  }
}
