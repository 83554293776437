@import '../../styles/variables.scss';

.list {
  > div {
    display: flex;
    margin-bottom: $smallSpace;
  }
}

.inline {
  display: inline;
}

.date {
  width: 40%;
}