@import '../../../styles/variables.scss';

.incomplete {
  @include incompleteTitle;
}

.title {
  display: inline-block;
}

.subTitle {
  font-size: 14px;
}

.wideTitle {
  display: inline-block;
  width: 85%;
}

.alignTop {
  vertical-align: top;
}