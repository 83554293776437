@import '../../styles/variables.scss';

$border-width: 2px;
$diameter: 2em;
$speed: 1s;

.wrapper {
  transition: opacity 100ms;
  &.reloading {
    opacity: 0.5;
    pointer-events: none;
  }
}

.loading {
  position: relative;
  min-height: 2em;
}

.table {
  background-color: $otcWhite;
  min-height: 25em;
}

.error, .info {
  padding: 1.4em 0;
  color: $otcGray;
}

.spinner {
  width: 2em;
  height: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1em 0 0 -1em;

  &.left {
    left: 5%;
  }

  &:after {
    content:" ";
    margin: $border-width;
    border-radius: 50%;
    background: #fff;
  }
}

.inner, .spinner:after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.inner:before, .inner:after {
  position: absolute;
  top: 0;
  bottom: 0;
  content:" ";
}

.inner:before {
  left: 0;
  right: 50%;
  border-radius: $diameter 0 0 $diameter;
  background-image: linear-gradient(to bottom, $otcGray, #ffffff);
}

.inner:after {
  left: 50%;
  right: 0;
  border-radius: 0 $diameter $diameter 0;
  background-color: $otcGray;
}

// spinning animation
.inner {
  animation-name: rotate-inner;
  animation-duration: $speed;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate-inner {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
