@import '../../styles/variables.scss';

$avatarSize: 48px;

.container {
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatarSize;
    height: $avatarSize;
    color: $otcBlack;
    background-color: #D8D8D8;
    border: 1px solid $otcGray;
    font-size: 20px;
    font-weight: $fontWeightBlack;
    font-style: italic;
    border-radius: 50%;
    padding: $smallSpace;
  }

  .dropDown {
    position: absolute;
    min-width: 200px;
    right: 0px;
    padding-top: 13px;

    .options {
      background: white;
      border: 1px solid $otcGray;
      font-size: 14px;

      .list {
        list-style: none;
        padding: 0 $mediumSpace;

        .item {
          cursor: pointer;
          padding-bottom: $smallSpace;

          &:last-child {
            padding: 0;
          }

          a {
            display: block;
            color: inherit;

            &:hover {
              color: $otcGreen;
            }
          }
        }
      }

      span {
        &:hover {
          color: $otcGreen;
        }
      }
      .signout {
        cursor: pointer;
        border-top: 1px solid $otcGray;
        padding: $smallSpace $mediumSpace;

        &:hover {
          color: $otcGreen;
        }
      }
    }
  }
}