@import '../../styles/variables.scss';

.container {
  padding-top: $XXLargeSpace;
  width: 525px;
  margin: 0 auto;

  .lockup {
    display: flex;
    color: $otcGreen;

    svg {
      margin-right: $smallSpace;
      height: 24px;
      width: 24px;
    }
  }
}