@import '../../styles/variables.scss';

.container {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;

  .dropArea {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed $otcBlack;
    font-size: 20px;
    font-weight: $fontWeightRegular;
    width: 100%;
    height: 96px;
    margin-bottom: $smallSpace;
  }

  .active {
    opacity: .5;
  }
}