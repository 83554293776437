@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: $mediumSpace;
}

.label {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  padding: 2px $mediumSpace;

  &.more {
    background-color: $otcBlack;
    color: $otcWhite;
    font-weight: $fontWeightBlack;
    margin-bottom: 8px;
  }

  &.less {
    background-color: $otcWhite;
    border: 1px solid $otcBlack;
    color: $otcBlack;
    font-weight: $fontWeightSemiBold;
  }
}