@import '../../../styles/variables.scss';

.appFees {
  color: $otcGreen;
}

.list {
  >div {
    display: flex;
    margin-bottom: $smallSpace;
  }
}

.inline {
  display: inline;
}

.sampleCheck {
  width: 300px;
}

.reviewPayment {

  .label {
    font-weight: $fontWeightRegular;
  }

  .fieldValue {
    font-weight: $fontWeightSemiBold;
  }
}

.error {
  color: $otcErrorColor;
  margin-bottom: $smallSpace;
}

.errorTitle {
  margin-left: $smallSpace;
  font-weight: $fontWeightBold;
}