@import '../../styles/variables.scss';

$avatarSize: 48px;

.header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $otcDarkGray;
  width: 100%;
  height: $headerHeight;
  z-index: 1500;

  .container {
    display: flex;
    justify-content: space-between;
    max-width: $pageMaxWidth;
    margin: 0 auto;
    align-items: center;
    height: 100%;

    .title {
      color: $otcWhite;
      margin: 0;
      font-size: 32px;
      font-weight: 900;
      font-style: italic;

      .logo {
        width: 150px;
        vertical-align: middle;
      }
    }

    .userAvatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $avatarSize;
      height: $avatarSize;
      color: $otcBlack;
      background-color: #D8D8D8;
      border: 1px solid $otcGray;
      font-size: 20px;
      font-weight: $fontWeightBlack;
      font-style: italic;
      border-radius: 50%;
      padding: $smallSpace;
    }

    .env {
      margin-left: 5px;
      font-weight: 100;
      font-size: 20px;
    }
  }
}