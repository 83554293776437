@import '../../styles/variables.scss';

.incomplete {
  @include incompleteTitle;
}

.inline {
  display: inline-block;
}

div {
  position: relative;
}

.title {
  @include h1;
  margin: 0 0 20px;
}

.securityTitle {
  border-bottom: 1px solid $otcGray;
  margin-bottom: $mediumSpace;
  padding-bottom: $smallSpace;
}

.description {
  margin-bottom: $largeSpace;
}

.fieldGroup {
  > div {
    margin-bottom: $smallSpace;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.formSection {
  margin-bottom: $XLargeSpace;
}

.innerGrid {
  display: grid;
  grid-gap: $mediumSpace;
  grid-template-columns: repeat(6, 1fr);

  .col2 {
    grid-column: span 2;
  }

  .col3 {
    grid-column: span 3;
  }

  .col4 {
    grid-column: span 4;
  }

  .col5 {
    grid-column: span 5;
  }

  .col6 {
    grid-column: span 6;
  }
}

.entityList {
  > div {
    margin-bottom: $smallSpace;
  }
}

.checkboxList {
  > div {
    margin-bottom: $mediumSpace;
  }
}

.date {
  width: 40%;
}