@import '../../styles/variables.scss';

.container {
  .optionContainer {
    display: flex;
  }

  .details {
    margin-left: $XLargeSpace;
    min-width: 400px;
  }
}